import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Header from "../components/headerpartnership"

const Partnership = () => (
  <Layout>
    <SEO title="Partnership" />

    <Header />
    <section className="section bg-primary">
      <div className="container-fluid py-4">
        <div className="row">
          <div className="col">
            <h1 className="h3 my-2 font-weight-bold text-light">
              WANT TO PARTNER WITH US? YOU ARE ONE CLICK AWAY!
            </h1>
          </div>
          <div className="col-auto">
            <a className="btn btn-sm btn-outline-light">
              Steps to partner with us
            </a>
          </div>
        </div>
      </div>
    </section>

    <section className="section bg-dark text-light">
      <div className="container-fluid text-center py-4">
        <div className="row py-4 justify-content-center">
          <div className="col-5">
            <h3>STEPS TO PARTNER WITH STUDY IN RWANDA</h3>
          </div>
        </div>
      </div>
    </section>

    <section className="section py-8  px-8 pb-9">
      <div className="container-fluid">
        <div className="py-8">
          <div className="row row-grid justify-content-between align-items-center">
            <div className="col-lg-7 order-lg-2">
              <h5 className="h3 text-primary font-weight-bold">INVESTMENT STEPS</h5>
              <p className="my-4">
                <ul>
                  <li>Partnership proposal to study in Rwanda</li>
                  <li>
                    Study in Rwanda will consult Ministry of Education for next
                    step
                  </li>
                  <li>
                    Send accreditation institutions documents, registered
                    business license and equivalence letter from local domestic
                    proving that you ‘are fully registered and teaching
                    curriculum if any
                  </li>
                  <li>We will sign Memorandum of understanding (MoU)</li>
                  <li>
                    We will advise which best institutions to partner with
                  </li>
                  <li>
                    Please note that service and consultation fees may apply
                  </li>
                </ul>
              </p>
            </div>
            <div className="col-lg-5 order-lg-1">
              <div className="card mb-0">
                <div className="card-body p-2">
                  <img
                    alt="Image placeholder"
                    src={require("../images/downloads/DSC_0083.jpg")}
                    className="img-fluid shadow rounded"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="py-8">
          <div className="row row-grid justify-content-center align-items-center">
            <div className="col-lg-7">
              <h5 className="h3 text-primary font-weight-bold">
                Study in Rwanda tips and Recommendations.
              </h5>
              <p className="my-4">
                <ul>
                  <li>
                    We collaborate with global colleges and universities. These
                    investors may increase a strategic vision and leadership,
                    according to the international academic standards before the
                    investment.
                  </li>
                  <li>
                    We recommend Global talent funding for universities with
                    strong academic research backgrounds in order to accelerate
                    Rwanda towards excellent status for research universities
                    and colleges that may be interested to collaborate with
                    Rwandan institutions for further exchange skills.
                  </li>
                  <li>
                    We partner with distinguished African universities from the
                    rest of the world with emphasis on international staff and
                    students to influence the African education system.
                  </li>
                </ul>
              </p>

              <p className="my-4">
                Send your inquiries to info@studyinrwanda.rw
              </p>
              <p className="my-4">
                Study in Rwanda will get back to you before 24 working hours.
              </p>
            </div>
            <div className="col-lg-5">
              <div className="card mb-0 ml-lg-5">
                <div className="card-body p-2">
                  <img
                    alt="Image placeholder"
                    src={require("../images/downloads/DSC_0017.jpg")}
                    className="img-fluid shadow rounded"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="py-8">
          <div className="row row-grid justify-content-center align-items-center">
            <div className="col-lg-7">
              <h5 className="h3 text-primary font-weight-bold">
                THE UNIVERSITY OF RWANDA
              </h5>
              <p className="font-weight-bold">The University of Rwanda is one of the Leading University in East Africa. Join us for joint degree programs. University of Rwanda also offers opportunities for partners willing to introduce new and high demand courses!</p>
              <p>Study in Rwanda is the official agency of the University of Rwanda.</p>
              <p className="my-4">
                <p>University of Rwanda is ready to partner with you for the following engineering courses:</p>
                <ul>
                  <li>
                  Engineering, computing-robotic engineering (aerospace and automotive)
                  </li>
                  <li>
                  Arts and design (Modeling, Graphics, Airport management, supply chain management and craft)
                  </li>
                  <li>
                  Science (Biological and environmental sciences, Cyber security and artificial intelligence, law and criminology)
                  </li>
                  <li>Engineering courses, Trainings (Petroleum, Energy and Gas, environmental engineering</li>
                </ul>
              </p>
            </div>
            <div className="col-lg-5 order-first">
              <div className="card mb-0 ml-lg-5">
                <div className="card-body p-2">
                  <img
                    alt="Image placeholder"
                    src={require("../images/downloads/RY6B0758.jpg")}
                    className="img-fluid  rounded"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="section py-2 px-8 pb-9">
      <div className="container-fluid">
        <div className="py-4">
          <div className="row row-grid justify-content-center align-items-center">
            <div className="col-auto">
              <h5 className="h3 text-primary text-center font-weight-bold">
                Work with us, we are looking for Partners!
              </h5>
              <p className=" my-4">
                Rwanda is focused on growth and development. Educational
                development is key to foreign institutions that would like to
                set up campuses within the country. Study in Rwanda is taking a
                very different approach when it comes to investment and
                enhancing the education system to eventually make Rwanda a
                leading African nation in the education system.
              </p>
              <p className=" my-4">
                Education is highly regarded as a norm and a basic part of human
                growth all over the world. According to (ESSP 2008), education
                in Rwanda is classified as a vital tool towards poverty
                reduction and sustainable socioeconomic development.
              </p>
              <p className="my-4">
                Attracting more investments in our education system especially
                internationally qualified Higher Learning Institutions
                contribute to the improvement of the desired quality of
                education and employment that in turn will meet all our
                educational standards.{" "}
              </p>
              <p className="my-4">
                Study in Rwanda’s mission is to promote international education
                services in Africa through recruitment programs, quality
                education with worldwide accreditation focusing mainly on the
                African market, with a Vision of making Rwanda the best study
                destination in Africa, promoting international tertiary
                education through investment in African institutions.
              </p>
              <p className="font-weight-bold lead text-primary my-4">
                We are ready to boost your investment!
              </p>
              <p className="my-4">
                Study in Rwanda is determined to further improve quality of
                education by:
                <ul>
                  <li>
                    Working with foreign Higher Learning Institutions to have
                    access on local market to promote creativity and advancement
                    in multicultural environment.{" "}
                  </li>
                  <li>
                    Advocating for high demand and marketable courses to Africa
                    to help find solutions to African problems as well as
                    satisfy students’ subject of interest within the country.
                  </li>
                  <li>Enhancing partnership in the international arena.</li>
                  <li>
                    Marketing the country, Africa in general across the globe
                    and Universities programs with the study in Rwanda graduate
                    abroad concept.
                  </li>
                </ul>
              </p>
              <div>
                <p className="font-weight-bold lead text-primary my-4">
                  Beneficiaries and Target Investors
                </p>
                <p className="my-4">
                  We work with world-class accredited universities and highly
                  ranked academic institutions around the world. Extend market
                  to Rwanda! Enjoy affordable internet, and infrastructure
                  designed for institutions. Study in Rwanda will make opening
                  institutions easy and possible.
                </p>
                <p className="my-4">
                  Are you looking for a reliable institution to Partner with in
                  Rwanda?
                </p>
                <p className="my-4 font-weight-bold"> Make a smart move!</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </Layout>
)

export default Partnership
